import React, { useState } from 'react';
import { Row, Col, Card, Nav } from 'react-bootstrap';
import { Table } from 'react-bootstrap';
import ColumnsTable from 'views/admin/dataTables/components/ColumnsTable';
import ComplexTable from 'views/admin/dataTables/components/ComplexTable';
import tableDataColumns from 'views/admin/dataTables/variables/tableDataColumns.json';
import tableDataComplex from 'views/admin/dataTables/variables/tableDataComplex.json';
import {
  columnsDataDevelopment,
  columnsDataCheck,
  columnsDataColumns,
  columnsDataComplex,
} from 'views/admin/dataTables/variables/columnsData';
import NotificationTable from 'views/admin/dataTables/components/NotificationTable';

const Notifications = () => {
  const [activeTab, setActiveTab] = useState('active');

  return (
    <React.Fragment>
      <Row>
        <Col>
          <Card style={{ marginBlock: '70px' }}>
            <Card.Header>
              <Card.Title
                as="h1"
                style={{ fontSize: '20px', color: 'orange', fontWeight: '900' }}
              >
                <code>Notications</code>
              </Card.Title>
              <span className="d-block mt-2 text-muted">
                Welcome to AI-Enhanced Interview Practice, your gateway to a
                smarter, more effective approach to interview preparation
                tailored to your industry.
              </span>
            </Card.Header>
           
      
            <Card.Body>
              {/* <NestedTable tab={activeTab} /> */}
              {/* <Table responsive>
                <thead>
                  <tr>
                    <th>#</th>
                    <th>First Name</th>
                    <th>Last Name</th>
                    <th>Username</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th scope="row">1</th>
                    <td>Mark</td>
                    <td>Otto</td>
                    <td>@mdo</td>
                  </tr>
                  <tr>
                    <th scope="row">2</th>
                    <td>Jacob</td>
                    <td>Thornton</td>
                    <td>@fat</td>
                  </tr>
                  <tr>
                    <th scope="row">3</th>
                    <td>Larry</td>
                    <td>the Bird</td>
                    <td>@twitter</td>
                  </tr>
                </tbody>
              </Table> */}
              <NotificationTable
                columnsData={columnsDataColumns}
                tableData={tableDataColumns}
              />
              {/* <ComplexTable
                columnsData={columnsDataComplex}
                tableData={tableDataComplex}
              /> */}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default Notifications;
