import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { ReactComponent as SvgDotPatternIcon } from "images/dot-pattern.svg";
import { SectionHeading as HeadingTitle } from "../misc/Headings.js";
import image1 from "../../Assets/Images/freelancer-looking-his-phone-project.jpg"
import image2 from "../../Assets/Images/person-working-remotely-project.jpg"
import image3 from "../../Assets/Images/interviewer-reading-applicants-long-resume.jpg"

const Container = tw.div`relative bg-white`;


const SingleColumn = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;

const HeadingInfoContainer = tw.div`flex flex-col items-center`;
const HeadingDescription = tw.p`mt-4 font-medium text-gray-600 text-center max-w-sm`;

const Content = tw.div`mt-16`;

const Card = styled.div(props => [
  tw`mt-24 md:flex justify-center items-center`,
  props.reversed ? tw`flex-row-reverse` : "flex-row"
]);
const Image = styled.div(props => [
  `background-image: url("${props.imageSrc}");`,
  tw`rounded md:w-1/2 lg:w-5/12 xl:w-1/3 flex-shrink-0 h-80 md:h-144 bg-cover bg-center mx-4 sm:mx-8 md:mx-4 lg:mx-8`
]);
const Details = tw.div`mt-4 md:mt-0 md:max-w-md mx-4 sm:mx-8 md:mx-4 lg:mx-8`;
const Subtitle = tw.div`font-bold tracking-wide text-secondary-100`;
const Title = tw.h4`text-3xl font-bold text-gray-900`;
const Description = tw.p`mt-2 text-sm leading-loose`;
const Link = tw.a`inline-block mt-4 text-sm text-primary-500 font-bold cursor-pointer transition duration-300 border-b-2 border-transparent hover:border-primary-500`;

const SvgDotPattern1 = tw(
  SvgDotPatternIcon
)`absolute top-0 left-0 transform -translate-x-20 rotate-90 translate-y-8 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern2 = tw(
  SvgDotPatternIcon
)`absolute top-0 right-0 transform translate-x-20 rotate-45 translate-y-24 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern3 = tw(
  SvgDotPatternIcon
)`absolute bottom-0 left-0 transform -translate-x-20 rotate-45 -translate-y-8 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern4 = tw(
  SvgDotPatternIcon
)`absolute bottom-0 right-0 transform translate-x-20 rotate-90 -translate-y-24 -z-10 opacity-25 text-primary-500 fill-current w-24`;

export default () => {
  const cards = [
    {
      imageSrc:image1,
      subtitle: "Fairness",
      title: "Fair Hiring Practices",
      description:
        "Maintaining Ethics and Fairness in AI Hiring AI automation offers many benefits, but it's vital to combine it with human oversight to ensure fair and unbiased hiring decisions in video interviews.",   
        url: "/login"
    
      },


    {
      imageSrc: image2,
      subtitle: "Assessments",
      title: "Advanced Candidate Assessments",
      description:
        "By leveraging natural language processing and facial recognition technology, AI video interviews analyze candidate responses and non-verbal cues, aiding in comprehensive candidate assessment.",
      url: "/login"
    },

    {
      imageSrc: image3,
      subtitle: "Resumes",
      title: "Beyond Traditional Resumes ",
      description:
        "Deep Insights Through AI Video Interviews AI video interviews offer innovative screening methods that provide valuable insights beyond what traditional resumes can show.",
      url: "/login"
    }
  ];

  return (
    <Container>
      <SingleColumn>
        <HeadingInfoContainer>
          <HeadingTitle>Key Events and Insights</HeadingTitle>
          <HeadingDescription>
            Here are some of the most popular events by AI Video Interviews by professionals.
          </HeadingDescription>
        </HeadingInfoContainer>

        <Content>
          {cards.map((card, i) => (
            <Card key={i} reversed={i % 2 === 1}>
              <Image imageSrc={card.imageSrc} />
              <Details>
                <Subtitle>{card.subtitle}</Subtitle>
                <Title>{card.title}</Title>
                <Description>{card.description}</Description>
                <Link href={card.url}>Join Now</Link>
              </Details>
            </Card>
          ))}
        </Content>
      </SingleColumn>
      <SvgDotPattern1 />
      <SvgDotPattern2 />
      <SvgDotPattern3 />
      <SvgDotPattern4 />
    </Container>
  );
};
