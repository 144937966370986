import React, { useState } from 'react';
import { Row, Col, Card, Nav } from 'react-bootstrap';
import { Table, Badge, Dropdown, Menu } from 'antd';
import {
  Avatar,
  Box,

  FormLabel,
  Icon,
  Select,
  SimpleGrid,
  useColorModeValue,
} from '@chakra-ui/react';
import {
  MdAddTask,
  MdAttachMoney,
  MdBarChart,
  MdFileCopy,
} from 'react-icons/md';
import MiniStatistics from 'components/card/MiniStatistics';
import IconBox from 'components/icons/IconBox';
import Jobs from './Jobs';

import { Flex, Button } from '@chakra-ui/react';

const ActionsMenu = () => {
  const menu = (
    <Menu>
      <Menu.Item key="1">Action 1</Menu.Item>
      <Menu.Item key="2">Action 2</Menu.Item>
    </Menu>
  );

  // return (
  //   <Dropdown overlay={menu}>
  //     <a href="#">
  //       More <span className="anticon anticon-down" />
  //     </a>
  //   </Dropdown>
  // );
};

const ExpandedTable = ({ data }) => {
  const columns = [
    // { title: 'Date', dataIndex: 'date', key: 'date' },
    { title: 'Name', dataIndex: 'name', key: 'name' },
    { title: 'E-Mail', dataIndex: 'eMail', key: 'eMail' },
    { title: 'Location', dataIndex: 'location', key: 'location' },
    { title: 'CV Score', dataIndex: 'cvScore', key: 'cvScore' },
    { title: 'Upgrade Status', dataIndex: 'upgradeNum', key: 'upgradeNum' },

    {
      title: 'Action',
      dataIndex: 'operation',
      key: 'operation',
      render: () => (
        <Flex justifyContent="space-evenly" align="center">
          <Button
            color={textColor}
            style={{ backgroundColor: 'blue' }}
            fontSize="sm"
            fontWeight="700"
          >
            View CV
          </Button>
          <Button
            color={textColor}
            style={{ backgroundColor: 'green' }}
            fontSize="sm"
            fontWeight="700"
          >
            Accept
          </Button>
          <Button
            color={textColor}
            style={{ backgroundColor: 'red' }}
            fontSize="sm"
            fontWeight="700"
          >
            Reject
          </Button>
          <Button
            color={textColor}
            style={{ backgroundColor: 'orange' }}
            fontSize="sm"
            fontWeight="700"
          >
            Hold
          </Button>
          <ActionsMenu />
        </Flex>
      ),
    },
  ];

  const borderColor = useColorModeValue('gray.200', 'whiteAlpha.100');
  const textColor = useColorModeValue('secondaryGray.900', 'white');

  return <Table columns={columns} dataSource={data} pagination={false} />;
};

const NestedTable = ({ tab }) => {
  const mainColumns = [
    { title: 'SI.NO', dataIndex: 'sino', key: 'sino' },
    { title: 'Job Title', dataIndex: 'jobTitle', key: 'jobTitle' },
    // { title: 'Version', dataIndex: 'version', key: 'version' },
    // { title: 'Upgraded', dataIndex: 'upgradeNum', key: 'upgradeNum' },
    // { title: 'Creator', dataIndex: 'creator', key: 'creator' },
    // { title: 'Date', dataIndex: 'createdAt', key: 'createdAt' },
    // {
    //   title: 'Action',
    //   key: 'operation',
    //   render: () => <a href="#">Publish</a>,
    // },
  ];

  // Sample data filtered by tab selection
  const dataByTab = {
    active: [
      {
        key: 1,
        sino: 1,
        jobTitle: 'Devops Engineer',
      },
    ],
    hold: [
      {
        key: 2,
        sino: 2,
        jobTitle: 'Devops Engineer',
      },
    ],
    accept: [
      {
        key: 3,
        sino: 3,
        jobTitle: 'Devops Engineer',
      },
    ],
    reject: [],
  };

  const mainData = dataByTab[tab] || [];

  return (
    <Table
      className="components-table-demo-nested"
      columns={mainColumns}
      expandedRowRender={(record) => <ExpandedTable data={[record]} />}
      dataSource={mainData}
    />
  );
};

const Candidates = () => {
  const [activeTab, setActiveTab] = useState('active');
  const brandColor = useColorModeValue('brand.500', 'white');
  const boxBg = useColorModeValue('secondaryGray.300', 'whiteAlpha.100');
  return (
    <React.Fragment>
      <Row>
        <Col>
          <Card style={{ marginBlock: '70px' }}>
            <Card.Header>
              <Card.Title
                as="h1"
                style={{ fontSize: '20px', color: 'orange', fontWeight: '900' }}
              >
                <code>Search & Invite Candidates</code>
              </Card.Title>
              <span className="d-block mt-2 text-muted">
                Explore our candidate database effortlessly. Narrow down your
                search using filters that matter to you - skills, experience,
                and more. When you find the perfect match, send an interview
                invitation and kickstart the hiring process!
              </span>
            </Card.Header>
            <div
              className="report-box"
              style={{
               
              }}
            >
               <SimpleGrid
                columns={{ base: 1, md: 2, lg: 3, '2xl': 6 }}
                gap="20px"
                p="5"
              >
                <MiniStatistics
                  startContent={
                    <IconBox
                      w="56px"
                      h="56px"
                      bg={boxBg}
                      icon={
                        <Icon
                          w="32px"
                          h="32px"
                          as={MdBarChart}
                          color={brandColor}
                        />
                      }
                    />
                  }
                  growth="+23%"
                  name="Hold"
                  value="1"
                />
                <MiniStatistics
                  startContent={
                    <IconBox
                      w="56px"
                      h="56px"
                      bg={boxBg}
                      icon={
                        <Icon
                          w="32px"
                          h="32px"
                          as={MdAttachMoney}
                          color={brandColor}
                        />
                      }
                    />
                  }
                  growth="+23%"
                  name="Accept"
                  value="2"
                />
                <MiniStatistics
                  startContent={
                    <IconBox
                      w="56px"
                      h="56px"
                      bg={boxBg}
                      icon={
                        <Icon
                          w="32px"
                          h="32px"
                          as={MdAttachMoney}
                          color={brandColor}
                        />
                      }
                    />
                  }
                  growth="+23%"
                  name="Reject"
                  value="3"
                />
              </SimpleGrid>
            </div>
            <Card.Header>
              <Nav
                variant="tabs"
                className="candidate-tabs"
                activeKey={activeTab}
                onSelect={(selectedTab) => setActiveTab(selectedTab)}
              >
                <Nav.Item className="candidate-tab-btn">
                  <Nav.Link eventKey="active" style={{ color: 'green' }}>
                    Active
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item className="candidate-tab-btn">
                  <Nav.Link eventKey="hold" style={{ color: 'orange' }}>
                    Hold
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item className="candidate-tab-btn">
                  <Nav.Link eventKey="accept" style={{ color: 'blue' }}>
                    Accept
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item className="candidate-tab-btn">
                  <Nav.Link eventKey="reject" style={{ color: 'red' }}>
                    Reject
                  </Nav.Link>
                </Nav.Item>
              </Nav>
              <br />
              <Card.Title as="h5">
                <code style={{ color: 'red' }}>
                  {activeTab.charAt(0).toUpperCase() + activeTab.slice(1)}{' '}
                  Invitations
                </code>
              </Card.Title>
              <span className="d-block mt-2 text-muted">{`Showing ${activeTab} invitations.`}</span>
            </Card.Header>
            <Card.Body>
              <NestedTable tab={activeTab} />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default Candidates;
