import React, { useEffect } from 'react';
import './assets/css/App.css';
import { Routes, Route, Navigate } from 'react-router-dom';
import AOS from 'aos';
import GLightbox from 'glightbox';
import Swiper from 'swiper';
import PureCounter from '@srexi/purecounterjs';
import {} from 'react-router-dom';
import AuthLayout from './layouts/auth';
import AdminLayout from './layouts/admin';
import RTLLayout from './layouts/rtl';
import Home from './NewHome/NewHomePage';
import {
  ChakraProvider,
  // extendTheme
} from '@chakra-ui/react';
import 'aos/dist/aos.css';
import 'glightbox/dist/css/glightbox.min.css';
import initialTheme from './theme/theme'; //  { themeGreen }
import { useState } from 'react';
import Login from 'components/Login/Login';
import SignUp from './components/SignUp/SignUp';
import PrivacyPolicy from 'components/PrivacyPolicy/PrivacyPolicy';
import TermsAndCondition from 'components/TermsAndCondition/TermsAndCondition';
import SecurityCenter from 'components/SecurityCenter/SecurityCenter';

// Chakra imports

export default function Main() {
  useEffect(() => {
    // Initialize each plugin
    AOS.init();
    GLightbox();
    new Swiper('.swiper-container', {
      // Swiper options
    });
    new PureCounter();
  }, []);

  // eslint-disable-next-line
  const [currentTheme, setCurrentTheme] = useState(initialTheme);
  return (
    <ChakraProvider theme={currentTheme}>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/signUp" element={<SignUp />} />
        <Route path="/login" element={<Login />} />
        <Route path="/privacyPolicy" element={<PrivacyPolicy />} />
        <Route path="/termsAndCondition" element={<TermsAndCondition />} />
        <Route path="/securityCenter" element={<SecurityCenter />} />

        <Route path="auth/*" element={<AuthLayout />} />
        <Route
          path="admin/*"
          element={
            <AdminLayout theme={currentTheme} setTheme={setCurrentTheme} />
          }
        />
        <Route
          path="rtl/*"
          element={
            <RTLLayout theme={currentTheme} setTheme={setCurrentTheme} />
          }
        />
        <Route path="/" element={<Navigate to="/admin" replace />} />
      </Routes>
    </ChakraProvider>
  );
}
