import React, { useState } from 'react';
import { Row, Col, Card, Nav } from 'react-bootstrap';
import { Table, Badge, Dropdown, Menu } from 'antd';
import {
  Avatar,
  Box,
  Flex,
  FormLabel,
  Icon,
  Select,
  SimpleGrid,
  useColorModeValue,
} from '@chakra-ui/react';
import {
  MdAddTask,
  MdAttachMoney,
  MdBarChart,
  MdFileCopy,
} from 'react-icons/md';
import MiniStatistics from 'components/card/MiniStatistics';
import IconBox from 'components/icons/IconBox';

const ActionsMenu = () => {
  const menu = (
    <Menu>
      <Menu.Item key="1">Action 1</Menu.Item>
      <Menu.Item key="2">Action 2</Menu.Item>
    </Menu>
  );

  return (
    <Dropdown overlay={menu}>
      <a href="#">
        More <span className="anticon anticon-down" />
      </a>
    </Dropdown>
  );
};

const ExpandedTable = ({ data }) => {
  const columns = [
    { title: 'Date', dataIndex: 'date', key: 'date' },
    { title: 'Name', dataIndex: 'name', key: 'name' },
    {
      title: 'Status',
      key: 'state',
      render: () => (
        <span>
          <Badge status="success" />
          Finished
        </span>
      ),
    },
    { title: 'Upgrade Status', dataIndex: 'upgradeNum', key: 'upgradeNum' },
    {
      title: 'Action',
      dataIndex: 'operation',
      key: 'operation',
      render: () => (
        <span className="table-operation">
          <a href="#">Pause</a>
          <a href="#">Stop</a>
          <ActionsMenu />
        </span>
      ),
    },
  ];

  return <Table columns={columns} dataSource={data} pagination={false} />;
};

const NestedTable = ({ tab }) => {
  const mainColumns = [
    { title: 'Name', dataIndex: 'name', key: 'name' },
    { title: 'Platform', dataIndex: 'platform', key: 'platform' },
    { title: 'Version', dataIndex: 'version', key: 'version' },
    { title: 'Upgraded', dataIndex: 'upgradeNum', key: 'upgradeNum' },
    { title: 'Creator', dataIndex: 'creator', key: 'creator' },
    { title: 'Date', dataIndex: 'createdAt', key: 'createdAt' },
    {
      title: 'Action',
      key: 'operation',
      render: () => <a href="#">Publish</a>,
    },
  ];

  // Sample data filtered by tab selection
  const dataByTab = {
    active: [
      {
        key: 1,
        name: 'Screem',
        platform: 'iOS',
        version: '10.3.4.5654',
        upgradeNum: 500,
        creator: 'Jack',
        createdAt: '2014-12-24 23:12:00',
      },
    ],
    hold: [
      {
        key: 2,
        name: 'TestApp',
        platform: 'Android',
        version: '5.2.1',
        upgradeNum: 300,
        creator: 'Tom',
        createdAt: '2014-11-20 18:45:00',
      },
    ],
    accept: [
      {
        key: 3,
        name: 'LiveApp',
        platform: 'Web',
        version: '2.0.1',
        upgradeNum: 150,
        creator: 'Lucy',
        createdAt: '2014-10-15 10:22:00',
      },
    ],
    reject: [],
  };

  const mainData = dataByTab[tab] || [];

  return (
    <Table
      className="components-table-demo-nested"
      columns={mainColumns}
      expandedRowRender={(record) => <ExpandedTable data={[record]} />}
      dataSource={mainData}
    />
  );
};

const SheduleInterview = () => {
  const [activeTab, setActiveTab] = useState('active');
  const brandColor = useColorModeValue('brand.500', 'white');
  const boxBg = useColorModeValue('secondaryGray.300', 'whiteAlpha.100');
  return (
    <React.Fragment>
      <Row>
        <Col>
          <Card style={{ marginBlock: '70px' }}>
            <Card.Header>
              <Card.Title
                as="h1"
                style={{ fontSize: '20px', color: 'orange', fontWeight: '900' }}
              >
                <code>Accepted Invitations</code>
              </Card.Title>
              <span className="d-block mt-2 text-muted">
                Congratulations on accepting the invitation! Get prepared for a
                productive interview experience ahead.
              </span>
            </Card.Header>
            <div
              className="report-box"
              style={{
              
              }}
            >
               <SimpleGrid
                columns={{ base: 1, md: 2, lg: 3, '2xl': 6 }}
                gap="20px"
                p="5"
              >
                <MiniStatistics
                  startContent={
                    <IconBox
                      w="56px"
                      h="56px"
                      bg={boxBg}
                      icon={
                        <Icon
                          w="32px"
                          h="32px"
                          as={MdBarChart}
                          color={brandColor}
                        />
                      }
                    />
                  }
                  name="Hold"
                  growth="+23%"
                  value="1"
                />
                <MiniStatistics
                  startContent={
                    <IconBox
                      w="56px"
                      h="56px"
                      bg={boxBg}
                      icon={
                        <Icon
                          w="32px"
                          h="32px"
                          as={MdAttachMoney}
                          color={brandColor}
                        />
                      }
                    />
                  }
                  name="Accept"
                  growth="+23%"
                  value="2"
                />
                <MiniStatistics
                  startContent={
                    <IconBox
                      w="56px"
                      h="56px"
                      bg={boxBg}
                      icon={
                        <Icon
                          w="32px"
                          h="32px"
                          as={MdAttachMoney}
                          color={brandColor}
                        />
                      }
                    />
                  }
                  growth="+23%"
                  name="Reject"
                  value="3"
                />
              </SimpleGrid>
            </div>
            <Card.Header>
              <Nav
                variant="tabs"
                className="candidate-tabs"
                activeKey={activeTab}
                onSelect={(selectedTab) => setActiveTab(selectedTab)}
              >
                <Nav.Item className="candidate-tab-btn">
                  <Nav.Link eventKey="active" style={{ color: 'green' }}>
                    Active
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item className="candidate-tab-btn">
                  <Nav.Link eventKey="hold" style={{ color: 'orange' }}>
                    Hold
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item className="candidate-tab-btn">
                  <Nav.Link eventKey="accept" style={{ color: 'blue' }}>
                    Accept
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item className="candidate-tab-btn">
                  <Nav.Link eventKey="reject" style={{ color: 'red' }}>
                    Reject
                  </Nav.Link>
                </Nav.Item>
              </Nav>
              <br />
              <Card.Title as="h5">
                <code style={{ color: 'red' }}>
                  {activeTab.charAt(0).toUpperCase() + activeTab.slice(1)}{' '}
                  Invitations
                </code>
              </Card.Title>
              <span className="d-block mt-2 text-muted">{`Showing ${activeTab} invitations.`}</span>
            </Card.Header>
            <Card.Body>
              <NestedTable tab={activeTab} />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default SheduleInterview;
